<template>
  <div class="wrapper-content">
    <el-steps :active="active" class="steps-bar" align-center>
      <el-step title="添加报名"></el-step>
      <el-step title="报名信息"></el-step>
      <el-step title="支付费用"></el-step>
      <el-step title="报名成功"></el-step>
    </el-steps>

    <div class="steps-content">
      <div v-if="active == 0" class="steps-content-1">
        <el-button type="primary" @click="addPlayer('other')">添加人员</el-button>
        <el-button @click="addPlayer('self')">本人报名</el-button>

        <el-table ref="multipleTable" :data="tableData" class="tab_apply" @selection-change="handleSelectionChange">
          <el-table-column fixed type="selection" width="55" align="center"/>
          <el-table-column fixed type="index" label="序号" width="80" align="center"/>
          <el-table-column prop="name" label="姓名" align="center" />
          <el-table-column prop="sex" label="性别" width="80" align="center">
            <template slot-scope="scope">{{ scope.row.sex == 1 ? '男' : '女' }}</template>
          </el-table-column>
          <el-table-column prop="idNumber" label="证件号码" align="center">
          </el-table-column>
          <el-table-column prop="phone" label="手机号" width="150" align="center"></el-table-column>
<!--          <el-table-column prop="phone" label="地址" align="center">-->
<!--            <template slot-scope="scope">{{ scope.row.area }} {{ scope.row.address }}</template>-->
<!--          </el-table-column>-->
          <el-table-column fixed="right" label="操作" width="150" align="center">
            <template slot-scope="scope">
              <el-button @click.native.prevent="edit(scope.$index)" plain type="text" size="small">编辑</el-button>
              <el-button @click.native.prevent="handleDelete(scope.$index, tableData)" plain type="text" size="small" class="btn-delCertResume">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="table-btn_bar">
          <el-button size="small" type="text">
            <el-checkbox v-model="checked" @change="toggleSelection(tableData)">全选</el-checkbox>
          </el-button>
          <span class="apply-num">共<i class="danger">{{ multipleSelection.length }}</i> 位报名者</span>
        </div>
      </div>
      <div v-show="active == 1" class="steps-content-2">
        <div class="content-bg1">
          <div class="step-title">课程信息</div>
          <div class="step-detail">
            <div class="step-content-info">
              <h2>
                {{ course.name }}
                <el-tag v-if="course.status == 5" type="success" effect="dark" size="mini">筹备中</el-tag>
                <el-tag v-if="course.status == 7" type="danger" effect="dark" size="mini">报名中</el-tag>
                <el-tag v-if="course.status == 8" type="info" effect="dark" size="mini">报名已结束</el-tag>
                <el-tag v-if="course.status == 9" effect="dark" size="mini">活动中</el-tag>
                <el-tag v-if="course.status == 10" type="info" effect="dark" size="mini">活动已结束</el-tag>
              </h2>
              <p>
                <span class="name">授课时间：</span>
                <span class="value">{{ formatDateYMD(course.startTime) }} -{{ formatDateYMD(course.endTime) }}</span>
              </p>
              <p>
                <span class="name">授课地址：</span>
                <span class="value">{{ course.address }}</span>
              </p>
              <p>
                <span class="name">授课方式：</span>
                <span class="value">{{ course.model == 1 ? '线上视频' : course.model == 2 ? '线上资料' : '线下' }}</span>
              </p>
              <p>
                <span class="name">报名费用：</span>
                <span v-if="course.collectFee == 1" class="value danger">¥<i class="fz22">{{ course.courseFee }}</i></span>
                <span class="btn-delCertResume" v-else><i class="fz22">免费</i></span>
              </p>
            </div>
          </div>
        </div>
        <div class="content-bg2">
          <div class="step-title">报名信息</div>
              <el-table :data="tableData" key="key2"  class="tab_apply" style="margin-top: 20px;" tooltip-effect="dark" border>
                <el-table-column fixed type="index" label="序号" width="80" align="center"></el-table-column>
                <el-table-column prop="name" label="报名人员" align="center"/>
                <el-table-column prop="sex" label="性别" width="80" align="center">
                  <template slot-scope="scope">{{ scope.row.sex == 1 ? '男' : '女' }}</template>
                </el-table-column>
                <el-table-column prop="idNumber" label="证件号码" align="center"></el-table-column>
                <el-table-column prop="phone" label="手机号" width="150" align="center"></el-table-column>
<!--                <el-table-column prop="address" label="联系地址" align="center"></el-table-column>-->
                <el-table-column label="原价(元)" width="150" align="center">
                  <template v-if="course.collectFee==0">0</template>
                  <template v-else>{{course.courseFee||0}}</template>
                </el-table-column>
                <el-table-column fixed="right" label="实付(元)" width="150" align="center">
                  <template v-if="course.collectFee==0">0</template>
                  <template v-else>{{isMember?course.memberFee:course.courseFee||0}}</template>
                </el-table-column>
              </el-table>
        </div>
        <div class="content-bg1" style="margin-top: 20px;margin-bottom: 0;">
          <div class="step-title">费用信息</div>
          <div class="feeBox">
            <div class="name">
              按人数收费：<span class="num">{{peopleFee.num}}</span>人共
              <span class="fee">{{course.collectFee==0?0:peopleFee.fee}}元</span>
            </div>
            <div class="name total">费用合计：<span class="fee">{{course.collectFee==0?0:peopleFee.fee}}元</span></div>
          </div>
        </div>
        <Pay v-if="payVisible" ref="Pay" />
      </div>
      <div v-if="active == 4" class="steps-content-4">
        <pay-result :payResult="payResult" :url="`/course-detail/${gItem.id}`" />
      </div>
    </div>
    <div class="btn-steps" v-if="active == 0 || active == 1">
      <el-button v-if="active == 0" @click="goBack">取消</el-button>
      <el-button v-if="active != 0" @click="prev">上一步</el-button>
      <el-button :disabled="nextchecked" type="primary" @click="next" :loading="btnLoading">下一步</el-button>
    </div>

    <!-- 添加报名人员 dialog -->
    <el-dialog title="添加人员" :visible.sync="bigDialogVisible" center class="certificationDialog big-dialog" @close="cancel">
      <!-- 添加人员 -->
      <el-form v-if="addPersonal" :model="personalForm" :rules="personalRules" ref="form" label-width="100px" class="add-apply_personal">
        <el-form-item label="参赛人员" prop="name">
          <el-input v-model="personalForm.name" placeholder="请输入参赛人员姓名"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input clearable v-model="personalForm.phone" placeholder="请输入手机号码" maxlength="11"></el-input>
        </el-form-item>
        <el-form-item label="证件号" prop="idNumber">
          <el-input maxlength="18" v-model="personalForm.idNumber" @blur="getSex" placeholder="请输入证件号码"></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="sex">
          <el-radio-group v-model="personalForm.sex">
            <el-radio label="1">男</el-radio>
            <el-radio label="2">女</el-radio>
          </el-radio-group>
        </el-form-item>
<!--        <el-form-item label="地区" prop="area">-->
<!--          <el-cascader :options="options" v-model="selectedOptions" @change="handleChange" style="width: 100%;"></el-cascader>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="地址" prop="address">-->
<!--          <el-input v-model="personalForm.address" placeholder="请输入地址"/>-->
<!--        </el-form-item>-->
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel()">取 消</el-button>
        <el-button type="primary" @click="submitForm()">确 定</el-button>
      </span>
    </el-dialog>


  </div>
</template>

<script>
import {regionData, CodeToText, TextToCode} from 'element-china-area-data'
import {
  applyForOther, getApplyDetailById, getUserInfo,
} from '@/api/course.js'
import {courseCourseApplyConfirmSign, courseCourseInfoInfo} from "../../api/course";
import {memberIsMember} from "../../api/partners";
import Pay from "@/pages/payment/pay.vue";
import payResult from "@/pages/payment/result.vue";
import TemplateFooter from "../template/second/templateFooter";

export default {
  name: 'course-signup',
  components: {TemplateFooter, Pay, payResult},
  data() {
    return {
      btnLoading:false,
      payVisible:false,
      active: 0,
      checked: false,
      checked2: false,
      bigDialogTitle: '',
      bigDialogVisible: false,
      addPersonal: false,
      multipleSelection: [],
      nextchecked: true,
      gItem:{},
      // 添加人员
      personalForm: {
        name: '',
        sex: '',
        idNumber: '',
        phone: '',
        // address: '',
        // area: '',
        forSelf: 0
      },
      editIndex: null,
      applyType: '',
      courseId: this.$route.query.courseId,
      userId: null,
      userInfo: null,
      selfDisable: false,
      //地区
      options: regionData,
      selectedOptions: [],
      //添加数据表
      tableData: [],
      personalRules: {
        name: [{required: true, message: '请输入姓名'}],
        sex: [{required: true, message: '请选择性别'}],
        idNumber: [
          {
            pattern: /^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X|x)$/,
            message: "请输入有效的身份证号",
            trigger: "blur",
          },
          {required: true, message: '请输身份证号码'}],
        phone: [
          {required: true, message: '请输入手机号', trigger: 'change'},
          {min: 7, max: 11, message: '请输入正确手机号', trigger: 'change'}
        ],
        address: [{required: true, message: '请输入地址'}],
        area: [{required: true, message: '请选择地区'}]
      },

      //报名人详情页
      applyDetail: {},
      tableData2: [],
      applyId: null,
      orderPrice: 0,
      price: 0,
      orderType: null,
      orderNumber: null,
      //qrcode
      qrcode: '',
      qrcodeVisible: false,
      websock: undefined,
      payType: null,
      qrPrice: 0,
      payResult: null,
      course:'',
      isMember:false,//true表示会员，false表示不是会员
      peopleFee:{ num: 0, fee: 0 },
    }
  },
  created() {
    this.init()
    this.initData(this.$route.query.courseId)
  },
  // mounted() {
  //   this.init()
  // },
  methods: {
    init() {
      getUserInfo().then((res) => {
        if (res.code === 200) {
          this.userInfo = res.data
        }
      })
    },
    initData(id){
      courseCourseInfoInfo(id).then(res=>{
        if(res.code == 200){
          this.course = res.data
          this.$nextTick(()=>{
            this.getMemberIsMember(res.data.tenantId)
          })
          this.$store.commit('hideLoading')
        }else {
          this.$message(res.msg)
          this.$store.commit('hideLoading')
        }
      }).catch(()=>{
        this.$message('请求出错，请重新刷新页面！');
        this.$store.commit('hideLoading')
      })
    },
    //查询当前用户是否为会员
    getMemberIsMember(tenantId){
      let userId = this.userInfo.id
      memberIsMember(tenantId,userId).then(res=>{
        if(res.code == 200){
          this.isMember = res.data
        }
      })
    },
    addPlayer(type) {
      this.addPersonal = true
      this.bigDialogVisible = true
      this.applyType = type
      if (type === 'self') {
        console.log(this.userInfo)
        this.personalForm.forSelf = 1
        this.personalForm.phone = this.userInfo.mobilePhone || ''
        this.personalForm.idNumber = this.userInfo.certificatesNumber || ''
        this.personalForm.name = this.userInfo.realName || ''
        this.personalForm.sex = this.userInfo.gender.toString() || ''
        // this.personalForm.address = this.userInfo.postalAddress || ''
        // this.personalForm.area = this.userInfo.area || ''
        // if (this.userInfo.area) {
        //   let provinceCode = this.personalForm.area.split('/')[0]
        //   let cityCode = this.personalForm.area.split('/')[1]
        //   let countyCode = this.personalForm.area.split('/')[2]
        //   this.selectedOptions = TextToCode[provinceCode][cityCode][countyCode].code
        // }
      }
    },
    getSex() {
      this.$set(this.personalForm,'sex',this.IdCard(this.personalForm.idNumber, 2))
    },
    //处理省市区
    handleChange() {
      let self = this
      let provinceCode = CodeToText[self.selectedOptions[0]] + '/'
      let cityCode = CodeToText[self.selectedOptions[1]] + '/'
      let countyCode = CodeToText[self.selectedOptions[2]]
      self.personalForm.area = provinceCode + cityCode + countyCode
    },
    resetForm() {
      this.personalForm = {
        name: '',
        sex: '',
        idNumber: '',
        phone: '',
        // address: '',
        // area: '',
        forSelf: 0
      }
      this.selectedOptions = []
      this.editIndex = null
    },
    //确认表单
    submitForm() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          if (this.editIndex !== null) {
            this.$set(this.tableData, this.editIndex, this.personalForm)
          } else {
            if (this.applyType === 'self') {
              this.selfDisable = true
            }
            this.tableData.push(this.personalForm)
          }
          this.bigDialogVisible = false
          this.resetForm()
        } else {
          return false
        }
      })
    },
    //编辑
    edit(index) {
      this.editIndex = index
      this.addPersonal = true
      this.bigDialogVisible = true
      this.personalForm = this.tableData[index]
      // let provinceCode = this.personalForm.area.split('/')[0]
      // let cityCode = this.personalForm.area.split('/')[1]
      // let countyCode = this.personalForm.area.split('/')[2]
      // this.selectedOptions = TextToCode[provinceCode][cityCode][countyCode].code

    },
    // 删除
    handleDelete(index, row) {
      row.splice(index, 1)
    },

    // 确认报名：下一步 1
    confirmationApply() {
      let applyData = {
        userId: this.userId,
        courseId: this.courseId,
        datas: this.multipleSelection
      }

      this.$store.commit('showLoading')
      this.nextchecked = true
      applyForOther(applyData).then((res) => {
        if (res.code == 200) {
          this.applyId = res.data.id
          this.active++
          getApplyDetailById({id: this.applyId}).then((res) => {
            if (res.code == 200) {
              this.applyDetail = res.data.courseInfo
              this.tableData2 = res.data.courseApplyList
              this.orderPrice = res.data.orderPrice
              this.orderType = res.data.orderType
              this.price = res.data.price
            }
          })
        } else {
          this.$message({
            type: 'warning',
            message: res.msg,
            duration: 1500
          })
        }
        this.$store.commit('hideLoading')
      })
    },

    goBack() {
      this.$router.go(-1);
    },
    prev() {
      this.active--
      if (this.active-- <= 0) this.active = 0
    },
    next() {
      if (this.active == 0) {
        // this.collectEnroll()
        this.active ++
        this.feeTotal()
      } else if (this.active == 1) {
        this.getCourseCourseApplyConfirmSign()
        // this.confirmationApply()
        // this.getOrderNumbers()
      } else if (this.active == 2) {
        // this.getOrderNumbers()
      } else if (this.active == 3) {
        this.active++
        // this.confirmationApply();
      } else if (this.active > 4) {
        this.active = 0
      }
    },
    //计算费用
    feeTotal(){
      this.peopleFee = { num: 0, fee: 0 }
      this.tableData.forEach(()=>{
        this.peopleFee.num += 1
        if(this.isMember){
          this.peopleFee.fee += this.course.memberFee
        }else {
          this.peopleFee.fee += this.course.courseFee
        }
      })
    },

    //确认报名
    getCourseCourseApplyConfirmSign(){
      let params = {
        "courseApplySignPersonnelForms": this.tableData,
        "courseId": this.course.id
      }
      // this.tableData.forEach(item=>{
      //   params.courseApplySignPersonnelForms.push({
      //     "forSelf": item.forSelf,
      //     "idNumber": item.idNumber,
      //     "name": item.name,
      //     "phone": item.phone,
      //     "sex": item.sex,
      //   })
      // })
      this.$store.commit("showLoading");
      this.btnLoading = true
      courseCourseApplyConfirmSign(params).then(res=>{
        if(res.code == 200){
          if(res.data.isPay){
            this.payVisible = true
            this.$nextTick(() => {
              this.$refs.Pay.init(res.data.orderNo,3)
            })
          }else {
            this.$router.push({ path: "/result", query: { type: 'success',memberPrice: '1' }, });
          }
          this.$store.commit("hideLoading");
          this.btnLoading = false
        }else {
          this.$store.commit("hideLoading");
          this.btnLoading = false
          return this.$message({message: res.msg,type: "error"});
        }
      }).catch(()=>{
        this.btnLoading = false
        this.$store.commit("hideLoading");
        return this.$message({message: '请求出错，请重新刷新页面！',type: "error"});
      })
    },

    // 人员报名信息处理
    handleSelectionChange(val) {
      this.multipleSelection = val
      if (this.multipleSelection.length > 0 && this.active == 0) {
        this.nextchecked = false
      } else {
        this.nextchecked = true
      }
    },
    // 全选：1
    toggleSelection(rows) {
      if (this.checked) {
        this.$refs.multipleTable.toggleAllSelection(rows)
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    },
    cancel() {
      this.bigDialogVisible = false
    },
  }
}
</script>

<style lang="scss" scoped>
.steps-bar {
  width: 100%;
  height: 52px;
  padding: 14px 0;
  margin-top: 40px;
  background: url("../../assets/image/detail-signupbg.png") center no-repeat;
}

.steps-content {
  width: 100%;
  padding-top: 30px;

  .steps-content-1 {
    min-height: 400px;
  }

  .tab_apply {
    width: 100%;
    margin-top: 30px;

    /deep/ .el-table__header th {
      background: #fafafa;
      border-top: 1px solid #e9ecee;
      padding-left: 4px;
      padding-right: 4px;
      font-weight: normal;
    }
  }

  .table-btn_bar {
    width: 100%;
    height: 50px;
    line-height: 50px;
    background: #ffffff;
    border-bottom: 1px solid #e9ecee;
    padding: 0 12px;
    box-sizing: border-box;

    .apply-num {
      padding-left: 16px;
      font-size: 14px;
      color: #555555;
    }

    .all-price {
      text-align: right;
      font-size: 16px;
      color: #555;

      .fz22 {
        font-size: 22px;
      }
    }
  }
}

.btn-steps {
  width: 100%;
  padding: 60px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-apply_item {
  .search-bar {
    padding-top: 0;
  }
}

//.step-content-info {
//  padding-left: 20px;
//  line-height: 36px;
//  font-size: 14px;
//  color: #555;
//
//  h2 {
//    font-size: 24px;
//    color: #333;
//    padding-bottom: 8px;
//  }
//
//  .fz22 {
//    font-size: 22px;
//  }
//
//  .price {
//    color: #FF1D00;
//    font-size: 22px;
//    .discount {
//      display: inline-block;
//      min-width: 110px;
//      height: 22px;
//      line-height: 22px;
//      background: #FF8001;
//      border-radius: 11px;
//      color: #fff;
//      font-size: 14px;
//      margin-left: 10px;
//      text-align: center;
//    }
//  }
//}

.steps-content-2,
.steps-content-3 {
  border: 1px solid #e4e4e4;
  padding: 20px;
  background: #ffffff;
}

.step-title {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  line-height: 26px;
}

.step-order-info {
  background: #f8f8f8;
  padding: 10px 20px;
  margin: 20px 0;
  line-height: 34px;
  font-size: 14px;
  color: #555;
  display: flex;
  flex-wrap: wrap;

  li {
    width: 50%;
  }
}

// 弹出层
.certificationDialog {
  .bold {
    font-weight: bold;
  }

  /deep/ .el-form-item__label {
    text-align: left;
  }

  /deep/ .el-dialog {
    width: 586px;
    box-shadow: 0px 9px 40px 0px rgba(75, 75, 75, 0.12);
    border-radius: 10px;
  }

  /deep/ .el-dialog__header {
    text-align: left;
    font-size: 18px;
    color: #333333;
    padding-bottom: 18px;
    border-bottom: solid 1px #e5e5e5;
  }
}

.step-detail {
  display: flex;
  padding: 20px 0;

  .step-content-image {
    width: 214px;
    height: 156px;
    overflow: hidden;

    img {
      //max-width: 100%;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.status-price {
  color: #666;
}

.apply-tip {
  color: #999999;
  font-size: 14px;
  line-height: 32px;
}

.apply-order {
  margin-top: 30px;
  padding: 20px 0;
  font-size: 14px;
  border-top: 1px solid #e8e8e8;
  color: #555555;
  line-height: 32px;

  h2 {
    font-size: 16px;
    font-weight: 400;
    color: #333333;
  }

  .name {
    display: inline-block;
    width: 128px;
  }

  .value {
    display: inline-block;
  }
}

.small-dialog {

  ::v-deep .el-dialog__header {
    border-bottom: solid 1px #e5e5e5;
  }
}

.qr-wrapper {
  background: #F9F9F9;
  width: 1200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #E4E4E4;
  border-radius: 6px 6px 0px 0px;

  .qr-title {
    width: 100%;
    box-sizing: border-box;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    height: 94px;
    padding: 24px 30px;

    .qr-price {
      font-size: 16px;
      color: #555555;

      .bold {
        font-size: 30px;
        color: #FF1D00;
      }
    }
  }

  .qr-code {
    width: 486px;
    height: 486px;
    background: #FFFFFF;
    border: 1px solid #E4E4E4;
    box-shadow: 0px 9px 40px 0px rgba(75, 75, 75, 0.12);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    //justify-content: center;
    align-items: center;
    margin-top: 36px;
    margin-bottom: 92px;

    .top {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      color: #333333;
      margin-top: 38px;

      .price {
        font-size: 22px;
        color: #FF1D00;
        padding-top: 24px;
      }
    }

    .bottom {
      display: flex;
      justify-content: space-between;
      width: 160px;
      margin-left: 56px;

      img {
        width: 36px;
        height: 32px;
      }

      .scan-title {
        margin-top: -4px;
        width: 112px;
        font-size: 16px;
        color: #333333;

        span {
          padding-bottom: 4px;
        }
      }
    }
  }
}

.steps-content-4 {

  .result-wrapper {
    width: 1200px;
    height: 208px;
    background: #FFFFFF;
    border: 1px solid #E4E4E4;
    border-radius: 6px;
    margin-top: 30px;
    margin-bottom: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .pay-result {

      h3 {
        margin-bottom: 20px;
        font-size: 24px;
        line-height: 44px;
        font-weight: bold;
        color: #333333;

        span {
          font-size: 44px;
          padding-right: 14px;
          vertical-align: middle;
        }

        .icon-chenggong {
          color: #5bc001;
        }

        .icon-shibai {
          color: #ff3f35;
        }
      }

      .red {
        color: #FF1D00;
        font-size: 24px;

      }
    }
  }

}
.btn-delCertResume {
  color: #f56c6c;
}
.content-bg1{
  background: #F2F2F2;
  padding: 10px;
  margin-bottom: 20px;
}
.content-bg2{
  background: #DEF0FA;
  padding: 10px;
}
.content-bg3{
  background: #f0f9eb;
  padding: 10px;
  margin-top: 20px;
}
.step-title {
  font-size: 22px;
  color: #333333;
  line-height: 26px;
  font-weight: bold;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
}
.step-content-info {
  padding-left: 20px;
  line-height: 30px;
  font-size: 14px;
  color: #555;
  h2 {
    font-size: 24px;
    color: #333;
    font-weight: bold;
  }
  .fz22 {
    font-size: 22px;
  }
}
.feeBox{
  padding-left: 20px;
  padding-top: 10px;
  margin-bottom: 20px;
  color: #333;
  .name{
    font-size: 16px;
    margin-top: 10px;
  }
  .num{
    color: rgb(64, 158, 255);
  }
  .fee{
    color: rgba(233,157,66,1);
  }
  .total{
    font-size: 20px;
    font-weight: bold;
  }
}
</style>
